
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import Badge from "@/components/Badge.vue";
import Tooltip from "@/components/Tooltip.vue";
import MandatoryFieldFlag from "@/components/Form/MandatoryFieldFlag.vue";

@Options({
  components: {
    Badge,
    Tooltip,
    MandatoryFieldFlag,
  },
  props: {
    /**
     * The caption for the row
     */
    caption: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The info/details for the row
     */
    details: {
      type: [String, Array] as PropType<string | Array<string>>,
      required: false,
    },
    /**
     * If set to true the details are shown as a badge
     */
    showDetailsAsBadge: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If set to true the details are shown in the badge,
     * otherwise the badge can be used for example purely as a status color
     * showDetailsAsBadge must also be set to true
     */
    showDetailsInBadge: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Background color of the badge
     */
    badgeBackgroundColor: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If set to true the details are shown as a list
     */
    detailsAreList: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If set to true the details are rendered as a HTML
     */
    detailsAreHtml: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    showTooltip: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    tooltipText: {
      type: String as PropType<string>,
      required: false,
    },
    tooltipColor: {
      type: String as PropType<string>,
      required: false,
    },
    tooltipTextColor: {
      type: String as PropType<string>,
      required: false,
    },
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
})
export default class DetailsRow extends Vue {}
