
import { Options, Vue } from "vue-class-component";
import Modal from "@/components/Modal.vue";
import DetailsRow from "@/components/DetailsRow.vue";
import CheckboxInput from "@/components/Form/CheckboxInput.vue";
import OfferService from "@/services/offer-service";
import { Constants } from "@/constants/constants";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import { PropType } from "vue";

@Options({
  components: {
    Modal,
    DetailsRow,
    CheckboxInput,
    LuSpinner,
  },
  props: {
    /**
     * The id of the offer
     */
    offerId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  data: () => ({
    /**
     * The note-taker offer to be accepted
     */
    offer: null,
    /**
     * Used to disable submit button
     */
    termsAccepted: false,
    /**
     * Used to disable terms accepted checkbox
     */
    haveClickedLink: false,
    /**
     * Used to show or hide spinner
     */
    isLoading: false,
  }),
  computed: {
    /**
     * Generates a URL to a PDF containing the terms and conditions
     */
    termsAndConditionsUrl(): string {
      const baseApiUrl = process.env.VUE_APP_BASE_API_URL;
      const apiFilesUrl = `${baseApiUrl}${Constants.NoteSupportFilesRelativeUrl}`;
      return this.$i18n.locale === "sv"
        ? `${apiFilesUrl}${Constants.NoteSupportTermsAndConditionsPdfSv}`
        : `${apiFilesUrl}${Constants.NoteSupportTermsAndConditionsPdfEn}`;
    },
  },
  methods: {
    getOffer(): void {
      if (this.$route.name === "OfferResponse" && this.$route.params.id) {
        const offerId = parseInt(this.$route.params.id);
        OfferService.getOfferById(offerId)
          .then((offer) => {
            if (offer?.confirmed || offer?.canceled) {
              return this.$router.push({
                name: "NotFound",
              });
            } else {
              this.offer = offer;
              if (
                !this.$store.state.currentUser.entitlements.includes(
                  "SuperAdmin"
                )
              ) {
                this.checkIfCorrectUser();
              }
            }
          })
          .catch((statusCode) => {
            if (statusCode === 404) {
              return this.$router.push({
                name: "NotFound",
              });
            } else {
              alert("Something went wrong");
            }
          });
      }
    },
    checkIfCorrectUser(): void {
      if (
        this.offer.noteTaker.luEduPersonPrimaryId !==
        this.$store.state.currentUser.luEduPersonPrimaryId
      ) {
        this.$router.push({
          name: "Unauthorized",
        });
      }
    },
    acceptOffer(): void {
      this.isLoading = true;
      OfferService.acceptOffer(this.offer).then((result: boolean) => {
        this.isLoading = false;
        if (result) {
          this.$refs["accepted-modal"].open();
        } else {
          this.$refs["error-modal"].open();
        }
      });
    },
    declineOffer(): void {
      this.isLoading = true;
      OfferService.declineOffer(this.offerId).then((result: boolean) => {
        this.isLoading = false;
        if (result) {
          this.$refs["declined-modal"].open();
        } else {
          this.$refs["error-modal"].open();
        }
      });
    },
  },
  async mounted() {
    this.getOffer();
  },
  watch: {
    $route() {
      this.getOffer();
    },
  },
})
export default class NoteTakingRequest extends Vue {}
