
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The Id of the checkbox
     */
    fieldId: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The label value for the checkbox
     */
    label: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The aria label value for the checkbox
     */
    ariaLabel: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the checkbox is checked
     */
    checked: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the checkbox is disabled
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * The help text for the checkbox
     */
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the input is required
     */
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
  },
  emits: ["update:checked", "update"],
  methods: {
    /**
     * Gets called when the value changes
     */
    onUpdate(isChecked: boolean): void {
      this.setValidityMessage("");
      this.$emit("update:checked", isChecked);
      this.$emit("update", isChecked);
    },
    /**
     * Sets a custom validity message
     *
     * @param {string} message The message
     */
    setValidityMessage(message: string): void {
      this.$refs.input.setCustomValidity(message);
    },
  },
})
export default class CheckboxInput extends Vue {}
